<template>
  <v-card class="px-6 px-md-8 pb-12 pt-8">
    <v-card-title class="justify-center text-h5 text-sm-h4 font-weight-bold">
      <div class="d-flex flex-column align-center">
        <v-btn absolute left top icon @click="handleBack">
          <v-icon v-text="'mdi-arrow-left'"/>
        </v-btn>
        <span class="bebas-neue"> Crie sua conta </span>
        <v-img :src="require('@/assets/logo.png')" max-width="200px"/>
      </div>
    </v-card-title>

    <v-form v-model="valid" ref="form" autocompalete="off" class="mb-6">
      <div @keypress.enter.prevent="submit()" class="mb-4">
        <div class="label-input">Nome</div>
        <v-text-field
          v-model="form.firstName"
          class="rounded-lg"
          background-color="accent"
          placeholder="Seu nome"
          :rules="rules"
          solo
          flat
          @keydown.space.prevent
        />

        <div class="label-input">Sobrenome</div>
        <v-text-field
          v-model="form.lastName"
          class="rounded-lg"
          background-color="accent"
          placeholder="Seu sobrenome"
          :rules="rules"
          solo
          flat
          @keydown.space.prevent
        />

        <div class="label-input">E-mail</div>
        <v-text-field
          v-model="form.email"
          class="rounded-lg"
          background-color="accent"
          placeholder="exemplo@email.com"
          :rules="emailRules"
          solo
          flat
          @keydown.space.prevent
        />

        <div class="label-input">Telefone</div>
        <v-text-field
          v-model="form.phone"
          v-mask="'(##) #####-####'"
          placeholder="(##) #####-####"
          class="rounded-lg"
          background-color="accent"
          :rules="phoneRules"
          solo
          flat
          @keydown.space.prevent
        />

        <div class="label-input">Senha</div>
        <v-text-field
          v-model="form.password"
          class="rounded-lg"
          background-color="accent"
          :type="showPass ? 'text' : 'password'"
          placeholder="Sua senha"
          :rules="passwordRules"
          solo
          flat
          @keydown.space.prevent
        >
          <template v-slot:append>
            <v-btn icon @click="showPass = !showPass">
              <v-icon>{{ showPass ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </div>

      <v-btn
        color="gradient"
        class="rounded-lg text-none"
        depressed
        large
        block
        dark
        @click.prevent="submit()"
      >
        Criar conta
      </v-btn>
    </v-form>

    <div class="d-flex justify-center">
      <router-link class="text-decoration-none text-center" to="/auth">
        <span class="subtext--text subtext--text"> Já possui uma conta? </span>

        <span class="font-weight-bold primart--text"> Efetuar acesso </span>
      </router-link>
    </div>

    <loader-hover v-if="loading"/>
  </v-card>
</template>

<script>
import {signUp} from "@/services/auth";
import {displayAlert, capitalize} from "@/utils";
import {mask} from "vue-the-mask";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      showPass: false,
      form: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        password: "",
      },
    };
  },

  directives: {mask},

  watch: {
    ["form.firstName"](v) {
      this.form.firstName = capitalize(v);
    },

    ["form.lastName"](v) {
      this.form.lastName = capitalize(v);
    },
  },

  computed: {
    _callbackUrl: function () {
      const defaultUrl = 'https://arenajoguefacil.com.br'
      const callbackUrl = this.$route.query.callback_url
      return callbackUrl || defaultUrl
    },
    emailRules() {
      return [
        (v) => !!v || "Campo Obrigatório",
        (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
      ];
    },

    phoneRules() {
      return [
        (v) => !!v || "Campo Obrigatório",
        (v) => v.length === 15 || "Telefone inválido",
      ];
    },

    passwordRules() {
      return [
        (v) => !!v || "Campo Obrigatório",
        (v) => v.length >= 6 || "Senha inválida",
      ];
    },

    rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          email: this.form.email,
          password: this.form.password,
          phone: this.form.phone.replaceAll(/[^0-9]/gim, ""),
          ddi: "55",
        };

        await signUp(payload).then((res) => {
          const {token, refreshToken} = res.body;

          this.redirect(token, refreshToken);
        });
      } catch (err) {
        this.displayAlert(err.data.message, 1);
      } finally {
        this.loading = false;
      }
    },

    redirect(token, refreshToken) {
      const callback = this.$route.query.callback_url || this.$store.getters.defaultURL;
      const url = `${callback}?tk=${token}&rtk=${refreshToken}`;

      window.open(url, '_self');
    },

    handleBack() {
      window.location.replace(this._callbackUrl);
    },

    displayAlert,
  },
};
</script>

<style>
</style>
